import { Component } from "react";

import SuccessNotification from "./SuccessNotification";
import ProjectCreator from "./ProjectCreator";
import SignatureCanvas from 'react-signature-canvas';

class TransportDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loadingtime: this.props.transport.LoadingTime,
        submitting: false,
        successMessage: '',
        signature: props.transport.Signature64,
        showTransportLines: false,
    };

    this.handleChangeLoadingtime = this.handleChangeLoadingtime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSignatureLoaded = this.handleSignatureLoaded.bind(this);
    this.handleChangeDetails = this.handleChangeDetails.bind(this);
  }

  clearMessage() {
    clearInterval(this.timer);
    this.setState({successMessage: ""});
  }

  handleChangeLoadingtime(event) {
    this.setState({loadingtime: event.target.value})
  }

  handleSubmit(e) {
    this.postPackingTime();

    if (this.sigCanvas && !this.sigCanvas.isEmpty()) {
      this.postSignature();
    }

    e.preventDefault();
  }

  handleSignatureLoaded(event) {
    event.target.classList.remove('spinner');
  }

  handleChangeDetails() {
    this.setState({
      showTransportLines : !this.state.showTransportLines
    });
  }

  postPackingTime() {
    this.setState({submitting: true});

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + this.props.token);

    var raw = JSON.stringify({
      "fieldData": {
        "LoadingTime": this.state.loadingtime
      }
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    var form = this;
    var url = "https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/transports/records/" + this.props.transport.recordId;
    fetch(url, requestOptions)
      .then(response => response.text())
      .then(function(result){
        form.setState({
          submitting: false,
          successMessage: 'Inpaktijd aangepast'
        });
        clearInterval(form.timer);
        form.timer = setTimeout(form.clearMessage.bind(form), 5000);
      })
      .catch(error => console.log('error', error));
  }

  postSignature() {
    var url = "https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/transports/records/" + this.props.transport.recordId + "/containers/signature";

    const canvas = this.sigCanvas.getCanvas()
    this.setState({
      signature: canvas.toDataURL()
    });

    canvas.toBlob((blob) => {
      var formdata = new FormData();
      formdata.append('upload', blob, 'signature.png');

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + this.props.token);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(url, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    });
  }

  render() {
    var submitButtonClass = "btn " + (this.state.submitting ? 'btn-disabled' : 'btn-primary');

    var details = this.state.showTransportLines
    ? <div className="transportlines">
        <ProjectCreator transport={this.props.transport} token={this.props.token} username={this.props.username} />
      </div>
    : <div className="details">
      <SuccessNotification message={this.state.successMessage} />
      <div className="card-actions">
        <form className="container flex flex-col mx-auto space-y-6 ng-untouched ng-pristine ng-valid" onSubmit={this.handleSubmit}>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Inpaktijd</span>
            </label>
            <label className="input-group">
              <input type="text" className="input input-bordered w-full max-w-xs text-right" value={this.state.loadingtime} onChange={this.handleChangeLoadingtime} />
              <span>minuten</span>
            </label>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Handtekening</span>
            </label>
              { this.state.signature === ""
                ? <SignatureCanvas ref={(ref) => { this.sigCanvas = ref }} penColor='blue' canvasProps={{width: 320, height: 200, className: 'sigCanvas border-2'}} />
                : <img src={this.state.signature} alt="" width='320' height="200" className="border-2 spinner" onLoad={this.handleSignatureLoaded}></img>
              }
          </div>
          <button type="submit"  className={submitButtonClass} disabled={this.state.submitting}>Opslaan</button>
        </form>
      </div>
    </div>;

    return (
      <div className="flex flex-col items-center">
        <div className="card w-96 bg-base-100 shadow-xl">
          <div className="card-body">
            <div className="flex">
            <div className="flex-grow">
              <h2 className="card-title text-2xl">{this.props.transport["Transports_Customer::KlantNaam"]}</h2>
              <p className="text-sm">{this.props.transport.TransportID}</p>
            </div>
            <button className="btn btn-sm btn-ghost" onClick={this.handleChangeDetails}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg> 
            </button>
            </div>
            <div className="divider" />
            <div className="space-y-1">
              <span className="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-label="Email address" className="w-4 h-4">
                  <path fill="var(currentColor)" d="M253.924,127.592a64,64,0,1,0,64,64A64.073,64.073,0,0,0,253.924,127.592Zm0,96a32,32,0,1,1,32-32A32.037,32.037,0,0,1,253.924,223.592Z"/>
                  <path fill="var(currentColor)" d="M376.906,68.515A173.922,173.922,0,0,0,108.2,286.426L229.107,472.039a29.619,29.619,0,0,0,49.635,0L399.653,286.426A173.921,173.921,0,0,0,376.906,68.515Zm-4.065,200.444L253.925,451.509,135.008,268.959C98.608,213.08,106.415,138.3,153.571,91.142a141.92,141.92,0,0,1,200.708,0C401.435,138.3,409.241,213.08,372.841,268.959Z"/>
                </svg>
                <span>{this.props.transport.Address}</span>
              </span>
              <span className="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-label="Contact" className="w-4 h-4">
                  <path fill="currentColor" d="M472,48H40A24.028,24.028,0,0,0,16,72V440a24.028,24.028,0,0,0,24,24h88V405.178A20.01,20.01,0,0,1,138.284,387.7l91.979-51.123L200,260.919V200a56,56,0,0,1,112,0v60.919l-30.263,75.655L373.716,387.7h0A20.011,20.011,0,0,1,384,405.178V464h88a24.028,24.028,0,0,0,24-24V72A24.028,24.028,0,0,0,472,48Zm-8,384H416V405.178a52.027,52.027,0,0,0-26.738-45.451h0L321.915,322.3,344,267.081V200a88,88,0,0,0-176,0v67.081L190.085,322.3l-67.347,37.432A52.027,52.027,0,0,0,96,405.178V432H48V80H464Z"></path>
                </svg>
                <span>{this.props.transport.RequestorName}</span>
              </span>
              <span className="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-label="Phonenumber" className="w-4 h-4">
                  <path fill="currentColor" d="M449.366,89.648l-.685-.428L362.088,46.559,268.625,171.176l43,57.337a88.529,88.529,0,0,1-83.115,83.114l-57.336-43L46.558,362.088l42.306,85.869.356.725.429.684a25.085,25.085,0,0,0,21.393,11.857h22.344A327.836,327.836,0,0,0,461.222,133.386V111.041A25.084,25.084,0,0,0,449.366,89.648Zm-20.144,43.738c0,163.125-132.712,295.837-295.836,295.837h-18.08L87,371.76l84.18-63.135,46.867,35.149h5.333a120.535,120.535,0,0,0,120.4-120.4v-5.333l-35.149-46.866L371.759,87l57.463,28.311Z"></path>
                </svg>
                <span>{this.props.transport.Phone}</span>
              </span>
            </div>
            <div className="divider" />
            {details}
          </div>
        </div>
      </div>
    );
  }
}

export default TransportDetail