import { Component } from "react";


import TransportDetail from "../components/TransportDetail";
import Spinner from "../components/Spinner";
import TransportList from "../components/TransportList";

class TransportListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: false,
            activeItem: false,
        };
    }

    componentDidMount() {
        this.showList = this.showList.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.loadList();

        // Refresh list every 5 minutes. This also keeps the token active.
        // Otherwise token will expire after 15 minutes of inactivity.
        this.interval = setInterval(() => this.loadList(), 5 * 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    loadList() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.token);

        // DateTransport = today
        var raw = JSON.stringify({
            "query": [
              {
                "DateTransport": "//",
                "Transports_Employee::Initialen": "==\"" + this.props.username + "\"",
              }
            ]
          });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        var page = this;
        fetch("https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/transports/_find", requestOptions)
            .then(response => response.text())
            .then(function(result) {
                result = JSON.parse(result);
                console.log("LOADING", result);

                // No records match the request.
                if (result.messages[0].code === "401") {
                    page.setState({ list: [] });
                }
                // Invalid token.
                else if (result.messages[0].code === "952") {
                    page.props.onTokenChange(false);
                }
                else {
                    page.setState({ list: result.response.data.map((item) => Object.assign({}, item.fieldData, {recordId: item.recordId})) });
                }
            })
            .catch(error => console.log('error', error));
    }

    showDetails(transport) {
        this.setState({activeItem: transport});
    }

    showList() {
        this.loadList();
        this.setState({activeItem: false});
    }

    render() {
        if (this.state.list === false) {
            return <Spinner />
        }
        else if(this.state.activeItem) {
            return (
                <div>
                    <div className="navbar bg-base-100 mb-5">
                        <div className="flex-none">
                            <button onClick={this.showList} className="btn btn-square btn-ghost">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                            </button>
                        </div>
                        <div className="flex-1">
                            <span className="btn btn-ghost normal-case text-xl">Details</span>
                        </div>
                        <div className="flex-none">
                            <button className="btn btn-square btn-ghost">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>
                            </button>
                        </div>
                    </div>

                    <TransportDetail transport={this.state.activeItem} token={this.props.token} username={this.props.username} />
                </div>
            );
        }
        else {
            return <TransportList items={this.state.list} onItemSelected={this.showDetails} />
        }
    }
}

export default TransportListPage;