import React from 'react';
import { Component } from "react";

import LoginPage from './pages/LoginPage';
import TransportListPage from './pages/TransportListPage';

class App  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      username: '',
    }
    this.handleChangeToken = this.handleChangeToken.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
  }

  handleChangeToken(token) {
    this.setState({'token': token});
  }

  handleChangeUsername(username) {
    this.setState({'username': username});
  }

  render() {
    if (this.state.token) {
      return <TransportListPage token={this.state.token} username={this.state.username} onTokenChange={this.handleChangeToken} />;
    }
    else {
      return <LoginPage onTokenChange={this.handleChangeToken} onUsernameChange={this.handleChangeUsername} />;
    }
  }
}

export default App;
