import { Component } from "react";

import ProjectInfo from "./ProjectInfo";
import TransportLine from "./TransportLine";

class ProjectCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lines: [],
            activeLine : false,

            titleEdit: '',
            boxesEdit: '',
            descriptionEdit: '',

            title: '',
            boxes: '',
            description: '',

            projectTitleEmptyError: false,
            creatingProject: false,
            currentProjectID: false,
        };

        this.activeLine = false;
    }

    componentDidMount() {
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleBoxesChange = this.handleBoxesChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleSaveProject = this.handleSaveProject.bind(this);
        this.handleEditProject = this.handleEditProject.bind(this);
        this.handleRemoveProject = this.handleRemoveProject.bind(this);

        this.loadTransportLines();
    }

    handleTitleChange(event) {
        this.setState({
            titleEdit: event.target.value,
            projectTitleEmptyError: false
        })
    }
    handleBoxesChange(event) {
        this.setState({boxesEdit: event.target.value});
    }
    handleDescriptionChange(event) {
        this.setState({descriptionEdit: event.target.value});
    }

    handleEditProject(event) {
        this.setState({
            projectTitleEmptyError: false
        })
    }

    handleSaveProject(event) {
        if (this.state.titleEdit === "") {
            this.setState({
                projectTitleEmptyError: true
            })
            event.preventDefault();

            document.getElementById('projectEditForm').scrollTop = 0;
            return;
        }

        this.setState({
            title: this.state.titleEdit,
            boxes: this.state.boxesEdit,
            description: this.state.descriptionEdit
        });

        if (!this.activeLine || this.activeLine.ProjectTitle !== this.state.titleEdit) {
            // After create project has executed asynchronously, the activeLine will be updated or created.
            console.log('createProject: ', this.state.titleEdit);
            this.createProject(this.state.titleEdit);
        }
        else if (this.activeLine) {
            console.log('Updating project');
            this.updateTransportLine(this.state.activeLine.ProjectID, this.state.descriptionEdit, this.state.boxesEdit, 'IN')
        }
    }

    createProject(title) {
        if (!this.activeLine) {
            this.activeLine = {}
        }
        this.activeLine.ProjectTitle = title;

        this.setState({
            creatingProject : true
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.token);

        var raw = JSON.stringify({
          "fieldData": {
            "Project": title,
            "CustomerID": this.props.transport.CustomerID
          }
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        var projectCreator = this;
        var url = "https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/projects/records";
        fetch(url, requestOptions)
          .then(response => response.text())
          .then(function(result){
            result = JSON.parse(result);
            console.log('Result from createProject', result);
            projectCreator.loadProject(result.response.recordId)
          })
          .catch(error => console.log('error', error));
    }

    loadProject(recordId) {
        console.log('loadProject', recordId);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.token);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        var projectCreator = this;
        var url = "https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/projects/records/" + recordId;
        fetch(url, requestOptions)
          .then(response => response.text())
          .then(function(result){
            projectCreator.setState({
                creatingProject: true
            });
            result = JSON.parse(result);
            projectCreator.activeLine.ProjectID = result.response.data[0].fieldData.ProjectID;
            if (Object.hasOwn(projectCreator.activeLine, 'recordId')) {
                projectCreator.updateTransportLine(projectCreator.activeLine.ProjectID, projectCreator.state.description, projectCreator.state.boxes, 'IN');
            }
            else {
                projectCreator.createTransportLine(projectCreator.activeLine.ProjectID, projectCreator.state.description, projectCreator.state.boxes, 'IN');
            }
          })
          .catch(error => console.log('error', error));

    }

    createTransportLine(projectID, description, boxes, in_out) {
        this.setState({
            creatingProject : true,
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.token);

        boxes = parseInt(boxes);
        if (isNaN(boxes)) {
            boxes = "";
        }

        var raw = JSON.stringify({
          "fieldData": {
            "TransportID": this.props.transport.TransportID,
            "ProjectID": projectID,
            "Description": description,
            'Boxcount': boxes,
            'InOut': in_out,
          }
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        var projectCreator = this;
        var url = "https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/transportlines/records";
        fetch(url, requestOptions)
          .then(response => response.text())
          .then(function(result){
            result = JSON.parse(result);

            projectCreator.activeLine.recordId = result.response.recordId;
            projectCreator.setState({
                creatingProject : false
            });
          })
          .catch(error => console.log('error', error));
    }

    updateTransportLine(projectID, description, boxes, in_out) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.token);

        var raw = JSON.stringify({
            "fieldData": {
              "ProjectID": projectID,
              "Description": description,
              'Boxcount': boxes,
              'InOut': in_out,
            }
        });

        var requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        var projectCreator = this;
        var url = "https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/transportlines/records/" + this.activeLine.recordId;
        fetch(url, requestOptions)
          .then(response => response.text())
          .then(function(result){
            projectCreator.setState({
                creatingProject : false
            });
          })
          .catch(error => console.log('error', error));
    }

    handleRemoveProject(event) {
        event.preventDefault();

        this.setState({
            titleEdit: '',
            boxesEdit: '',
            descriptionEdit: '',
            title: '',
            boxes: '',
            description: ''
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.token);

        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          redirect: 'follow'
        };

        var url = "https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/transportlines/records/" + this.activeLine.recordId;
        fetch(url, requestOptions)
          .then(response => response.text())
          .then(function(result){
            result = JSON.parse(result);
            console.log("DELETED:", result);
          })
          .catch(error => console.log('error', error));

        this.activeLine = false;
    }

    loadTransportLines() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.token);

        // DateTransport = today
        var raw = JSON.stringify({
            "query": [
              {
                "TransportID": "=" + this.props.transport.TransportID,
              }
            ],
            "sort": [
                {"fieldName": "CreationTimestamp", "sortOrder": "ascend"},
            ]
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        var projectCreator = this;
        fetch("https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/layouts/transportlines/_find", requestOptions)
            .then(response => response.text())
            .then(function(result) {
                result = JSON.parse(result);

                // No records match the request.
                if (result.messages[0].code === "401") {
                    projectCreator.setState({ lines: [] });
                }
                // Invalid token.
                else if (result.messages[0].code === "952") {
                    projectCreator.props.onTokenChange(false);
                }
                else {
                    var lines = result.response.data.map((item) => Object.assign({}, item.fieldData, {recordId: item.recordId}));
                    var last = lines.slice(-1)[0];
                    console.log('LAST', last)
                    if (last.CreatedBy.toUpperCase() === projectCreator.props.username.toUpperCase() && last.InOut === "IN") {
                        console.log('setting state');
                        projectCreator.setState({
                            title: last["Transportlines_Project::Project"],
                            boxes: "" + last.Boxcount,
                            description: last.Description,
                            titleEdit: last["Transportlines_Project::Project"],
                            boxesEdit: "" + last.Boxcount,
                            descriptionEdit: last.Description,
                        })
                        projectCreator.activeLine = last;
                        lines.pop();
                    }

                    projectCreator.setState({ lines: lines });
                }
            })
            .catch(error => console.log('error', error));
    }

    render() {
        var titleInputClasses = "input input-bordered w-full";
        if (this.state.projectTitleEmptyError) {
            titleInputClasses += " border-red-600";
        }

        var label = "Transportlijn toevoegen";
        if (this.state.title) {
            label = "Transportlijn aanpassen";
        }

        const lines = this.state.lines.map((item, index) => (
            <li key={item.recordId}><TransportLine line={item} index={index} /></li>
        ));

        return (
            <div>
               <ul>
                  {lines}
               </ul>
               <ProjectInfo title={this.state.title} boxes={this.state.boxes} description={this.state.description} onRemove={this.handleRemoveProject} loading={this.state.creatingProject}/>
               <label htmlFor="editModal" className="btn btn-xs" onClick={this.handleEditProject}>{label}</label>

               <input type="checkbox" id="editModal" className="modal-toggle" />
                <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box" id="projectEditForm">
                    <div className="form-control">
                    <label className="label">
                        <span className="label-text">Project titel <span className="text-red-600">*</span></span>
                    </label>
                    <input type="text" className={titleInputClasses} value={this.state.titleEdit} onChange={this.handleTitleChange} />
                    </div>
                    <div className="form-control">
                    <label className="label">
                        <span className="label-text">Aantal dozen</span>
                    </label>
                    <input type="text" className="input input-bordered w-full" value={this.state.boxesEdit} onChange={this.handleBoxesChange} />
                    </div>
                    <div className="form-control">
                    <label className="label">
                        <span className="label-text">Omschrijving</span>
                    </label>
                    <textarea className="input input-bordered w-full h-32" onChange={this.handleDescriptionChange} value={this.state.descriptionEdit} />
                    </div>
                    <label htmlFor="editModal" className="btn mt-5" onClick={this.handleSaveProject}>Opslaan</label>
                    <label htmlFor="editModal" className="text-indigo-700 ml-5 cursor-pointer">Annuleren</label>
                </div>
            </div>
            </div>
        );
    }
}

export default ProjectCreator;