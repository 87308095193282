import { Component } from "react";

class ProjectInfo extends Component {
  render() {
    if(!this.props.title) {
        return null;
    }

    var boxes = <div className="text-xs">Aantal dozen: {this.props.boxes}</div>;
    if (this.props.boxes.trim() === "") {
        boxes = null;
    }

    if (this.props.loading) {
      return (
        <div className="alert shadow-lg bg-yellow-100">
          <div className="text-center m-auto">
            <img src="spinning-loading.gif" alt="loading..."></img>
          </div>
        </div>);
    }

    return(
      <div className="alert shadow-lg bg-yellow-100 flex-row">
        <div>
          <div>
            <h3 className="font-bold">{this.props.description}</h3>
            <div className="text-xs">{this.props.title}</div>
            {boxes}
          </div>
        </div>
        <div>
          <label htmlFor="removeModal" className="btn btn-xs btn-ghost"><svg className="svg-icon" style={{width: '1em', height: '1em', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z"  /></svg></label>

          <input type="checkbox" id="removeModal" className="modal-toggle" />
          <div className="modal modal-bottom sm:modal-middle">
          <div className="modal-box" id="projectEditForm">
              <h3 className="font-bold text-lg">Verwijder transportlijn</h3>
              <p className="py-4">Bent u zeker dat u deze transportlijn wilt verwijderen?</p>

              <label htmlFor="removeModal" className="btn btn-error mt-5" onClick={this.props.onRemove}>Verwijderen</label>
              <label htmlFor="removeModal" className="text-indigo-700 ml-5 cursor-pointer">Annuleren</label>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectInfo;