import { Component } from "react";

import LoginForm from '../components/LoginForm';

class LoginPage extends Component {
    render() {
        return (
            <div className="p-3 px-6 min-h-48 flex justify-center items-center">
                <div className="flex flex-col w-full max-w-md p-12 space-y-4 text-center bg-gray-50 text-gray-800">
                    <div>
                    <img
                        className="mx-auto h-12 w-auto"
                        src="logo192.png"
                        alt="Shuttle"
                    />
                    <h1 className="mt-6 text-3xl font-semibold">Meld je aan</h1>
                    </div>
                    <LoginForm onTokenChange={this.props.onTokenChange} onUsernameChange={this.props.onUsernameChange} />
                </div>
            </div>
        );
    }
}

export default LoginPage;