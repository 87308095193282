import { Component } from "react";

class TransportLine extends Component {
  render() {
    var classes = this.props.index % 2 ? "bg-gray-200" : "";

    var boxes = <div className="text-xs">Aantal dozen: {this.props.line.Boxcount}</div>;
    if (this.props.line.Boxcount === "") {
        boxes = null;
    }
    return(
      <div>
        <div className={classes + " p-2"}>
          <div className="flex flex-row"><h3 className="font-bold grow">{this.props.line.Description}</h3><div>{this.props.line.InOut}</div></div>
          <div className="text-xs">{this.props.line['Transportlines_Project::Project']} ({this.props.line.ProjectID})</div>
          {boxes}
        </div>
      </div>
    );
  }
}

export default TransportLine;