import { Component } from "react";

import ErrorNotification from "./ErrorNotification";

class LoginForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        login: '',
        password: '',
        error: '',
      }

      this.handleChangeLogin = this.handleChangeLogin.bind(this);
      this.handleChangePassword = this.handleChangePassword.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeLogin(event) {
      this.setState({ login: event.target.value });
    }
    handleChangePassword(event) {
      this.setState({ password: event.target.value });
    }

    handleSubmit(e) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic " + window.btoa(this.state.login + ':' + this.state.password));

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      var form = this;
      fetch("https://filemaker.youston.be/fmi/data/v1/databases/TrackAndTrace/sessions", requestOptions)
        .then(response => response.text())
        .then(function(result) {
          result = JSON.parse(result);
          if (result.messages[0].code !== "0") {
            form.setState({ error: result.messages[0].message });
          }
          else {
            form.setState({ error: ''});
            form.props.onTokenChange(result.response.token);
            form.props.onUsernameChange(form.state.login);
          }
        })
        .catch(error => console.error(error));
      e.preventDefault();
    }

    render() {
      return (
        <div className="form-wrapper">
          <ErrorNotification message={this.state.error} ></ErrorNotification>

          <form className="space-y-4 ng-untouched ng-pristine ng-valid" data-bitwarden-watching="1" onSubmit={this.handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="flex flex-col">
              <div>
                <label htmlFor="login" className="sr-only">Login</label>
                <input
                  id="login"
                  type="text"
                  placeholder="Login"
                  className="input w-full"
                  required
                  value={this.state.login}
                  onChange={this.handleChangeLogin}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Wachtwoord</label>
                <input
                  id="password"
                  type="password"
                  placeholder="Wachtwoord" 
                  autoComplete="current-password"
                  className="input w-full"
                  required
                  value={this.state.password}
                  onChange={this.handleChangePassword}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex items-center">
                <input type="checkbox" name="remember" id="remember" aria-label="Remember me" className="checkbox" />
                <label htmlFor="remember" className="label">Blijf aangemeld</label>
              </div>
              <a  href="/" className="link py-2">Wachtwoord vergeten?</a>
            </div>
            <button type="submit" className="btn">Aanmelden</button>
          </form>
        </div>
      );
    }
  }

  export default LoginForm