import { Component } from "react";

class TransportList extends Component {
  render() {
    const tableRows = this.props.items.map((item, index) => (

        <tr key={item.recordId}>
            <td>
                <p>{item["Transports_Customer::KlantNaam"]}</p>
            </td>
            <td>
                <p>{item.Address}</p>
            </td>
            <td>
                <button type="button" onClick={this.props.onItemSelected.bind(this, item)} className="btn btn-ghost btn-xs">Details</button>
            </td>
        </tr>
    ));

    return (

        <div className="container p-2 mx-auto sm:p-4 text-gray-800">
            <h2 className="mb-4 text-2xl font-semibold leading-tight">Mijn ritten van vandaag</h2>
            <div className="overflow-x-auto w-full">
                <table className="table w-full">
                    <colgroup>
                        <col />
                        <col />
                        <col className="w-24" />
                    </colgroup>
                    <thead>
                        <tr className="text-left">
                            <th>Klant</th>
                            <th>Adres</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </div>
        </div>

    );
  }
}

export default TransportList