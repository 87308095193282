import { Component } from "react";

class Spinner extends Component {
  render() {
    return(
      <div className="p-3 px-6 min-h-48 flex h-screen justify-center items-center">
        <progress className="progress w-56"></progress>
      </div>
    );
  }
}

export default Spinner;